//export const UNI_LIST = 'https://gateway.ipfs.io/ipns/tokens.uniswap.org'
//export const UNI_EXTENDED_LIST = 'https://gateway.ipfs.io/ipns/extendedtokens.uniswap.org'
export const UNI_EXTENDED_LIST = ''
//const UNI_UNSUPPORTED_LIST = 'https://gateway.ipfs.io/ipns/unsupportedtokens.uniswap.org'
const BA_LIST = 'https://raw.githubusercontent.com/The-Blockchain-Association/sec-notice-list/master/ba-sec-list.json'
// TODO(INFRA-179): Re-enable CMC list once we have a better solution for handling large lists.
// const CMC_ALL_LIST = 'https://s3.coinmarketcap.com/generated/dex/tokens/eth-tokens-all.json'
//const COINGECKO_LIST = 'https://tokens.coingecko.com/uniswap/all.json'
//const WRAPPED_LIST = 'wrapped.tokensoft.eth'

// eslint-disable-next-line import/no-unused-modules
export const PULSECHAIN_LIST = 'https://raw.githubusercontent.com/piteasio/app-tokens/main/piteas-tokenlist.json'

export const UNSUPPORTED_LIST_URLS: string[] = []
//export const UNSUPPORTED_LIST_URLS: string[] = [BA_LIST]

// default lists to be 'active' aka searched across
export const DEFAULT_ACTIVE_LIST_URLS: string[] = [PULSECHAIN_LIST]
export const DEFAULT_INACTIVE_LIST_URLS: string[] = [
  PULSECHAIN_LIST,
  UNI_EXTENDED_LIST,
  //  CMC_ALL_LIST,
  //COINGECKO_LIST,
  //WRAPPED_LIST,
  ...UNSUPPORTED_LIST_URLS,
]

export const DEFAULT_LIST_OF_LISTS: string[] = [...DEFAULT_ACTIVE_LIST_URLS, ...DEFAULT_INACTIVE_LIST_URLS]
