import { Trans } from '@lingui/macro'
import styled from 'styled-components/macro'
import { ExternalLink, ThemedText } from 'theme'

const StyledLink = styled(ExternalLink)`
  font-weight: 600;
  color: ${({ theme }) => theme.textSecondary};
`

const LastUpdatedText = styled.span`
  color: ${({ theme }) => theme.textTertiary};
`
const ExplorerContainer = styled.div`
  width: 100%;
  margin-top: 15px;
  font-size: 20px;
  background-color: ${({ theme }) => theme.accentActionSoft};
  color: ${({ theme }) => theme.textSecondary};
  border-radius: 4px;
  padding: 8px 8px;
  display: flex;
  align-items: center;
  overflow: hidden;
`
const ExplorerLinkWrapper = styled.div`
  display: flex;
  overflow: hidden;
  align-items: center;
`

const ExplorerLink = styled.div`
  display: block;
  font-size: 12px;
  font-weight: 500;
  line-height: 16px;
`

const LAST_UPDATED_DATE = '10 June 2023'

export default function PrivacyPolicyNotice() {
  return (
    <>
      <ThemedText.Caption color="textSecondary">
        <Trans>By connecting a wallet, you agree to Piteas&apos;</Trans>{' '}
        <StyledLink href="#">
          <Trans>Terms of Service</Trans>{' '}
        </StyledLink>
        <Trans>and consent to its</Trans>{' '}
        <StyledLink href="#">
          <Trans>Privacy Policy.</Trans>
        </StyledLink>
        <LastUpdatedText>
          {' ('}
          <Trans>Last Updated:</Trans>
          {` ${LAST_UPDATED_DATE})`}
        </LastUpdatedText>
      </ThemedText.Caption>
      <ExplorerContainer>
        <ExplorerLinkWrapper>
          <ExplorerLink>
            <u>If you are experiencing issues connecting, please following:</u><br /><br /> Add an extra '/'
            to the end of the URL.<br /> For example: (<strong>app.piteas.io//</strong>) or (<strong>app.piteas.io///</strong>)
          </ExplorerLink>
        </ExplorerLinkWrapper>
      </ExplorerContainer>
    </>
  )
}
