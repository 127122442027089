import { MixedRouteSDK } from '@uniswap/router-sdk'
import { Currency, CurrencyAmount, Percent, Token, TradeType } from '@uniswap/sdk-core'
import { Pair, Route as V2Route } from '@uniswap/v2-sdk'
import { FeeAmount, Pool, Route as V3Route } from '@uniswap/v3-sdk'
import { isPolygonChain, isPulseChain } from 'constants/chains'
import { nativeOnChain } from 'constants/tokens'
import JSBI from 'jsbi'
import { GetQuoteArgs } from './slice'
import {
  ClassicTrade,
  PiteasPathInRoute,
  PiteasRoute,
  PiteasSwapInRouteX,
  PoolType,
  QuoteData,
  QuoteState,
  SwapRouterNativeAssets,
  TradeResult,
  V2PoolInRoute,
  V3PoolInRoute,
} from './types'

/**
 * Transforms a Routing API quote into an array of routes that can be used to
 * create a `Trade`.
 */
export function computeRoutes(
  tokenInIsNative: boolean,
  tokenOutIsNative: boolean,
  routes: QuoteData['route'],
  srcAmount: QuoteData['srcAmount'],
  destAmount: QuoteData['destAmount'],
  srcToken: QuoteData['srcToken'],
  destToken: QuoteData['destToken']
):
  | {
      routes: PiteasRoute<Currency, Currency> | null
      routev3: V3Route<Currency, Currency> | null
      routev2: V2Route<Currency, Currency> | null
      mixedRoute: MixedRouteSDK<Currency, Currency> | null
      inputAmount: CurrencyAmount<Currency>
      outputAmount: CurrencyAmount<Currency>
    }[]
  | undefined {
  if (routes.swaps.length === 0) return []
  
  const tokenIn = srcToken //routes[0]?.[0]?.tokenIn
  const tokenOut = destToken //routes[0]?.[routes[0]?.length - 1]?.tokenOut
  if (!tokenIn || !tokenOut) throw new Error('Expected both tokenIn and tokenOut to be present')

  const parsedCurrencyIn = tokenInIsNative ? nativeOnChain(tokenIn.chainId) : parseToken(tokenIn)
  const parsedCurrencyOut = tokenOutIsNative ? nativeOnChain(tokenOut.chainId) : parseToken(tokenOut)
  //const routesx = [[{ tokenIn, tokenOut, srcAmount, destAmount } as PiteasSwapInRoute]]

  const {swaps, paths} = routes

  const routesx = [[{ tokenIn, tokenOut, srcAmount, destAmount } as PiteasSwapInRouteX]]
  try {
    return routesx.map((xroute, i) => {
      let route = routesx[0]

      if (route.length === 0) {
        throw new Error('Expected route to have at least one pair or pool')
      }
      const rawAmountIn = route[0].srcAmount
      const rawAmountOut = route[route.length - 1].destAmount
      //console.log(rawAmountIn, rawAmountOut)
      if (!rawAmountIn || !rawAmountOut) {
        throw new Error('Expected both amountIn and amountOut to be present')
      }
      
      return {
        routes: new PiteasRoute(swaps.map((swap, i) => {
          let tokens = paths[i].map(parseToken).concat(parseToken(tokenOut))
          return new Swaps(swap.percent, swap.subswaps.map((subswap, j) => new Subswap(tokens[j], tokens[j+1],subswap.percent, subswap.paths.map((path) => parsePiteasx(path) ))))
        }), parsedCurrencyIn, parsedCurrencyOut),
        routev3: null,
        routev2: null, //new V2Route(route.map(parsePiteas), parsedCurrencyIn, parsedCurrencyOut),
        mixedRoute: new MixedRouteSDK(route.map(parsePiteas), parsedCurrencyIn, parsedCurrencyOut),
        inputAmount: CurrencyAmount.fromRawAmount(parsedCurrencyIn, rawAmountIn),
        outputAmount: CurrencyAmount.fromRawAmount(parsedCurrencyOut, rawAmountOut),
      }
    })
    
    /*
    return routesx.map((route) => {
      if (route.length === 0) {
        throw new Error('Expected route to have at least one pair or pool')
      }
      const rawAmountIn = route[0].srcAmount
      const rawAmountOut = route[route.length - 1].destAmount
      //console.log(rawAmountIn, rawAmountOut)
      if (!rawAmountIn || !rawAmountOut) {
        throw new Error('Expected both amountIn and amountOut to be present')
      }
      //const isOnlyPiteas = isVersionedRoute<PiteasSwapInRoute>(PoolType.Piteas, route)
      //console.log(new MixedRouteSDK(route.map(parsePiteas), parsedCurrencyIn, parsedCurrencyOut))
      //console.log(new PiteasRoute(route.map(parsePiteas), parsedCurrencyIn, parsedCurrencyOut))
      const isOnlyV2 = true
      return {
        routes: new PiteasRoute(route.map(parsePiteas),route.map(parsePiteas), parsedCurrencyIn, parsedCurrencyOut),
        routev3: null,
        routev2: new V2Route(route.map(parsePiteas), parsedCurrencyIn, parsedCurrencyOut),
        mixedRoute: new MixedRouteSDK(route.map(parsePiteas), parsedCurrencyIn, parsedCurrencyOut),
        inputAmount: CurrencyAmount.fromRawAmount(parsedCurrencyIn, rawAmountIn),
        outputAmount: CurrencyAmount.fromRawAmount(parsedCurrencyOut, rawAmountOut),
      }
    })
    */
  } catch (e) {
    console.error('Error computing routes', e)
    return
  }
}

//
//
// export function computeRoutes(
// tokenInIsNative: boolean,
// tokenOutIsNative: boolean,
// routes: QuoteData['route']
// ):
// | {
//       routes: PiteasRoute<Currency, Currency> | null
//       routev3: V3Route<Currency, Currency> | null
//       routev2: V2Route<Currency, Currency> | null
//       mixedRoute: MixedRouteSDK<Currency, Currency> | null
//       inputAmount: CurrencyAmount<Currency>
//       outputAmount: CurrencyAmount<Currency>
//     }[]
// | undefined {
// if (routes.length === 0) return []
//
// const tokenIn = routes[0]?.[0]?.tokenIn
// const tokenOut = routes[0]?.[routes[0]?.length - 1]?.tokenOut
// if (!tokenIn || !tokenOut) throw new Error('Expected both tokenIn and tokenOut to be present')
//
// const parsedCurrencyIn = tokenInIsNative ? nativeOnChain(tokenIn.chainId) : parseToken(tokenIn)
// const parsedCurrencyOut = tokenOutIsNative ? nativeOnChain(tokenOut.chainId) : parseToken(tokenOut)
//
// try {
//     return routes.map((route) => {
//       if (route.length === 0) {
//         throw new Error('Expected route to have at least one pair or pool')
//       }
//       const rawAmountIn = route[0].amountIn
//       const rawAmountOut = route[route.length - 1].amountOut
//
//       if (!rawAmountIn || !rawAmountOut) {
//         throw new Error('Expected both amountIn and amountOut to be present')
//       }
//
//       const isOnlyV2 = isVersionedRoute<V2PoolInRoute>(PoolType.V2Pool, route)
//       const isOnlyV3 = isVersionedRoute<V3PoolInRoute>(PoolType.V3Pool, route)
//       const isOnlyPiteas = isVersionedRoute<V3PoolInRoute>(PoolType.V3Pool, route)
//
//       return {
//         routes: isOnlyPiteas ? new PiteasRoute(route.map(parsePool), parsedCurrencyIn, parsedCurrencyOut) : null,
//         routev3: isOnlyV3 ? new V3Route(route.map(parsePool), parsedCurrencyIn, parsedCurrencyOut) : null,
//         routev2: isOnlyV2 ? new V2Route(route.map(parsePair), parsedCurrencyIn, parsedCurrencyOut) : null,
//         mixedRoute:
//           !isOnlyV3 && !isOnlyV2
//             ? new MixedRouteSDK(route.map(parsePoolOrPair), parsedCurrencyIn, parsedCurrencyOut)
//             : null,
//         inputAmount: CurrencyAmount.fromRawAmount(parsedCurrencyIn, rawAmountIn),
//         outputAmount: CurrencyAmount.fromRawAmount(parsedCurrencyOut, rawAmountOut),
//       }
//     })
// } catch (e) {
//     console.error('Error computing routes', e)
//     return
// }
// }
//
const parsePoolOrPair = (pool: V3PoolInRoute | V2PoolInRoute): Pool | Pair => {
  return pool.type === PoolType.V3Pool ? parsePool(pool) : parsePair(pool)
}

function isVersionedRoute<T extends V2PoolInRoute | V3PoolInRoute>(
  type: T['type'],
  route: (V3PoolInRoute | V2PoolInRoute)[]
): route is T[] {
  return route.every((pool) => pool.type === type)
}

export function transformRoutesToTrade(args: GetQuoteArgs, data: QuoteData): TradeResult {
  const { tokenInAddress, tokenOutAddress, tradeType } = args
  const tokenInIsNative = Object.values(SwapRouterNativeAssets).includes(tokenInAddress as SwapRouterNativeAssets)
  const tokenOutIsNative = Object.values(SwapRouterNativeAssets).includes(tokenOutAddress as SwapRouterNativeAssets)
  const { gasUseEstimateUSD, blockNumber, methodParameters } = data
  const routes = computeRoutes(
    tokenInIsNative,
    tokenOutIsNative,
    data.route,
    data.srcAmount,
    data.destAmount,
    data.srcToken,
    data.destToken
  )
  //console.log("routes",routes)
  const trade = new ClassicTrade({
    v2Routes: [],
    v3Routes: [],
    mixedRoutes:
      routes
        ?.filter(
          (r): r is typeof routes[0] & { mixedRoute: NonNullable<typeof routes[0]['mixedRoute']> } =>
            r.mixedRoute !== null
        )
        .map(({ mixedRoute, inputAmount, outputAmount }) => ({
          mixedRoute,
          inputAmount,
          outputAmount,
        })) ?? [],
    piteasRoutes:
      routes
        ?.filter((r): r is typeof routes[0] & { routes: NonNullable<typeof routes[0]['routes']> } => r.routes !== null)
        .map(({ routes, inputAmount, outputAmount }) => ({
          routes,
          inputAmount,
          outputAmount,
        })) ?? [],
    tradeType,
    gasUseEstimateUSD: parseFloat(gasUseEstimateUSD).toFixed(2).toString(),
    methodParameters,
    //blockNumber,
  })

  return { state: QuoteState.SUCCESS, trade }
}

function parseToken({ address, chainId, decimals, symbol }: any ): Token {
  return new Token(chainId, address, parseInt(decimals.toString()), symbol)
}

function parsePool({ fee, sqrtRatioX96, liquidity, tickCurrent, tokenIn, tokenOut }: V3PoolInRoute): Pool {
  return new Pool(
    parseToken(tokenIn),
    parseToken(tokenOut),
    parseInt(fee) as FeeAmount,
    sqrtRatioX96,
    liquidity,
    parseInt(tickCurrent)
  )
}

const parsePair = ({ reserve0, reserve1 }: V2PoolInRoute): Pair =>
  new Pair(
    CurrencyAmount.fromRawAmount(parseToken(reserve0.token), reserve0.quotient),
    CurrencyAmount.fromRawAmount(parseToken(reserve1.token), reserve1.quotient)
  )

const parsePiteas = ({ tokenIn, srcAmount, tokenOut, destAmount }: PiteasSwapInRouteX): Pair =>
  new Pair(
    CurrencyAmount.fromRawAmount(parseToken(tokenIn), srcAmount),
    CurrencyAmount.fromRawAmount(parseToken(tokenOut), destAmount)
  )

class Swaps{
    percent:Percent
    subswaps: Subswap[]
    constructor(percent: Percent, subswaps: Subswap[]){
      this.percent = percent
      this.subswaps = subswaps
    }
  }

class Subswap{
  percent:Percent
  tokenA: Token
  tokenB: Token
  paths: Path[]
  constructor(tokenA: Token, tokenB: Token, percent: Percent, paths: Path[]){
    this.percent = percent
    this.paths = paths
    this.tokenA = tokenA;
    this.tokenB = tokenB;
  }
}

class Path {
  
  percent: Percent
  exchange: string
  address: string
  constructor(percent: Percent, address: string, exchange: string){
    
    this.percent = percent;
    this.address = address;
    this.exchange = exchange;
  }
}
const parsePiteasx = (x: PiteasPathInRoute): Path =>
  new Path(x.percent, x.address, x.exchange)

// TODO(WEB-2050): Convert other instances of tradeType comparison to use this utility function
export function isExactInput(tradeType: TradeType): boolean {
  return tradeType === TradeType.EXACT_INPUT
}

export function currencyAddressForSwapQuote(currency: Currency): string {
  if (currency.isNative) {
    return isPolygonChain(currency.chainId)
      ? SwapRouterNativeAssets.MATIC
      : isPulseChain(currency.chainId)
      ? SwapRouterNativeAssets.PLS
      : SwapRouterNativeAssets.ETH
  }

  return currency.address
}
