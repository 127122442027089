import { NumberType, formatNumber } from '@uniswap/conedison/format'
import { MixedRouteSDK, Trade } from '@uniswap/router-sdk'
import { Currency, CurrencyAmount, Percent, Price, Token, TradeType } from '@uniswap/sdk-core'
import { Route as V2Route } from '@uniswap/v2-sdk'
import { Route as V3Route } from '@uniswap/v3-sdk'
import { ConstructorFragment } from 'ethers/lib/utils'
import { useUSDPrice } from 'hooks/useUSDPrice'
import tryParseCurrencyAmount from 'lib/utils/tryParseCurrencyAmount'
import { computeFiatValuePriceImpact } from 'utils/computeFiatValuePriceImpact'
import { priceToPreciseFloat } from 'utils/formatNumbers'

export enum TradeState {
  LOADING,
  INVALID,
  NO_ROUTE_FOUND,
  VALID,
}

// from https://github.com/Uniswap/routing-api/blob/main/lib/handlers/schema.ts

type TokenInRoute = Pick<Token, 'address' | 'chainId' | 'symbol' | 'decimals'>

export type V3PoolInRoute = {
  type: 'v3-pool'
  tokenIn: TokenInRoute
  tokenOut: TokenInRoute
  sqrtRatioX96: string
  liquidity: string
  tickCurrent: string
  fee: string
  amountIn?: string
  amountOut?: string

  // not used in the interface
  address?: string
}

type V2Reserve = {
  token: TokenInRoute
  quotient: string
}

export type V2PoolInRoute = {
  type: 'v2-pool'
  tokenIn: TokenInRoute
  tokenOut: TokenInRoute
  reserve0: V2Reserve
  reserve1: V2Reserve
  amountIn?: string
  amountOut?: string

  // not used in the interface
  // avoid returning it from the client-side smart-order-router
  address?: string
}

export interface QuoteData {
  //quoteId?: string
  blockNumber: string
  srcToken: TokenInRoute
  destToken: TokenInRoute
  srcAmount: string
  destAmount: string
  //amountDecimals: string
  //gasPriceWei: string
  //gasUseEstimate: string
  //gasUseEstimateQuote: string
  //gasUseEstimateQuoteDecimals: string
  gasUseEstimateUSD: string
  methodParameters?: { calldata: string; value: string }
  //quote: string
  //quoteDecimals: string
  //quoteGasAdjusted: string
  //quoteGasAdjustedDecimals: string
  //route: Array<PiteasSwapInRoute[]>
  route: PiteasSwapInResponse
  //routeString: string
}

class PTrade<TInput extends Currency, TOutput extends Currency, TTradeType extends TradeType> extends Trade<
  TInput,
  TOutput,
  TTradeType
> {
  piteasRoutes: any
  constructor({
    piteasRoutes,
    v2Routes,
    v3Routes,
    tradeType,
    mixedRoutes,
  }: {
    piteasRoutes: any
    v2Routes: {
      routev2: V2Route<TInput, TOutput>
      inputAmount: CurrencyAmount<TInput>
      outputAmount: CurrencyAmount<TOutput>
    }[]
    v3Routes: {
      routev3: V3Route<TInput, TOutput>
      inputAmount: CurrencyAmount<TInput>
      outputAmount: CurrencyAmount<TOutput>
    }[]
    tradeType: any
    mixedRoutes?:
      | {
          mixedRoute: MixedRouteSDK<TInput, TOutput>
          inputAmount: CurrencyAmount<TInput>
          outputAmount: CurrencyAmount<TOutput>
        }[]
      | undefined
  }) {
    super({ v2Routes, v3Routes, tradeType, mixedRoutes })
    this.piteasRoutes = piteasRoutes
  }
}

export type PiteasPathInRoute = {
  percent: Percent
  exchange: string
  address: string
}

export type PiteasPathsInRoute = {
  percent: Percent
  paths: PiteasPathInRoute[]
}

export type PiteasSubswapInRoute = {
  percent: Percent
  subswaps: PiteasPathsInRoute[]
}

export type PiteasSwapInResponse = {
  paths: (TokenInRoute[])[]
  swaps: PiteasSubswapInRoute[]
}

export type PiteasSwapInRouteX = {
  tokenIn: TokenInRoute
  tokenOut: TokenInRoute
  srcAmount: string
  destAmount: string
  diagrams: Array<PiteasSubswapInRoute[]>
}

export class PiteasRoute<TInput extends Currency, TOutput extends Currency> {
  swaps: any | null
  //paths: Array<TokenInRoute[]>
  //pools: Array<PiteasPathInRoute[]>
  input: TInput
  output: TOutput
  constructor(swaps: any, input: TInput, output: TOutput) {
    this.input = input
    this.output = output
    this.swaps = swaps
    //this.paths = paths
    //this.pools = pools
  }

}

export class ClassicTrade<
  TInput extends Currency,
  TOutput extends Currency,
  TTradeType extends TradeType
> extends PTrade<TInput, TOutput, TTradeType> {
  gasUseEstimateUSD: string | null | undefined
  blockNumber: string | null | undefined
  methodParameters?: { calldata: string; value: string }
  priceImpactX: any
  constructor({
    gasUseEstimateUSD,
    blockNumber,
    methodParameters,
    ...routes
  }: {
    gasUseEstimateUSD?: string | null
    blockNumber?: string | null
    methodParameters?: { calldata: string; value: string }

    piteasRoutes: {
      routes: PiteasRoute<TInput, TOutput>
      inputAmount: CurrencyAmount<TInput>
      outputAmount: CurrencyAmount<TOutput>
    }[]
    v2Routes: {
      routev2: V2Route<TInput, TOutput>
      inputAmount: CurrencyAmount<TInput>
      outputAmount: CurrencyAmount<TOutput>
    }[]
    v3Routes: {
      routev3: V3Route<TInput, TOutput>
      inputAmount: CurrencyAmount<TInput>
      outputAmount: CurrencyAmount<TOutput>
    }[]
    tradeType: TTradeType
    mixedRoutes?: {
      mixedRoute: MixedRouteSDK<TInput, TOutput>
      inputAmount: CurrencyAmount<TInput>
      outputAmount: CurrencyAmount<TOutput>
    }[]
  }) {
    super(routes)
    this.blockNumber = blockNumber
    this.gasUseEstimateUSD = gasUseEstimateUSD
    this.methodParameters = methodParameters
    this.priceImpactX = new Percent(0, 100000)
  }

  priceImpactUSD(params: Percent) {
    this.priceImpactX = params
  }
  get priceImpact(): Percent {
    return this.priceImpactX
  }
}

/*
export class ClassicTrade<
  TInput extends Currency,
  TOutput extends Currency,
  TTradeType extends TradeType
> extends PTrade<TInput, TOutput, TTradeType> {
  gasUseEstimateUSD: string | null | undefined
  blockNumber: string | null | undefined

  constructor({
    gasUseEstimateUSD,
    blockNumber,
    ...routes
  }: {
    gasUseEstimateUSD?: string | null
    blockNumber?: string | null
    v2Routes: {
      routev2: V2Route<TInput, TOutput>
      inputAmount: CurrencyAmount<TInput>
      outputAmount: CurrencyAmount<TOutput>
    }[]
    v3Routes: {
      routev3: V3Route<TInput, TOutput>
      inputAmount: CurrencyAmount<TInput>
      outputAmount: CurrencyAmount<TOutput>
    }[]
    tradeType: TTradeType
    mixedRoutes?: {
      mixedRoute: MixedRouteSDK<TInput, TOutput>
      inputAmount: CurrencyAmount<TInput>
      outputAmount: CurrencyAmount<TOutput>
    }[]
  }) {
    super(routes)
    this.blockNumber = blockNumber
    this.gasUseEstimateUSD = gasUseEstimateUSD
  }
}
*/
export type InterfaceTrade = ClassicTrade<Currency, Currency, TradeType>

export enum QuoteState {
  SUCCESS = 'Success',
  NOT_FOUND = 'Not found',
}

export type QuoteResult =
  | {
      state: QuoteState.NOT_FOUND
      data?: undefined
    }
  | {
      state: QuoteState.SUCCESS
      data: QuoteData
    }

export type TradeResult =
  | {
      state: QuoteState.NOT_FOUND
      trade?: undefined
    }
  | {
      state: QuoteState.SUCCESS
      trade: InterfaceTrade
    }

export enum PoolType {
  V2Pool = 'v2-pool',
  V3Pool = 'v3-pool',
  Piteas = 'piteas',
}

// swap router API special cases these strings to represent native currencies
// all chains have "ETH" as native currency symbol except for polygon
export enum SwapRouterNativeAssets {
  MATIC = 'MATIC',
  ETH = 'ETH',
  PLS = 'PLS',
}

function parseToken({ address, chainId, decimals, symbol }: QuoteData['srcToken']): Token {
  return new Token(chainId, address, parseInt(decimals.toString()), symbol)
}
