import styled from 'styled-components/macro'

import { MAX_WIDTH_MEDIA_BREAKPOINT } from '../constants'

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  //background-color: ${({ theme }) => theme.backgroundSurface};
  //box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  //border: 1px solid ${({ theme }) => theme.backgroundOutline};
`

function BuyTable() {
  return (
    <GridContainer>
      <iframe
        id='iframe-widget'
        src="https://changenow.io/embeds/exchange-widget/v2/widget.html?FAQ=false&amount=0.1&amountFiat=250&backgroundColor=FFFFFF&darkMode=false&from=eth&fromFiat=usd&horizontal=false&isFiat=false&lang=en-US&link_id=8cf43fb9b779d2&locales=false&logo=false&primaryColor=db155d&to=pls&toFiat=pls&toTheMoon=true"
        style={{ width: '100%', height: '600px', border: 'none' }}
      ></iframe>
    </GridContainer>
  )
}

export default BuyTable
