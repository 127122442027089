import { useWeb3React } from '@web3-react/core'
import Row, { AutoRow, RowBetween } from 'components/Row'
import { getChainInfo } from 'constants/chainInfo'
import { SupportedChainId } from 'constants/chains'
import { PiteasMiniIcon } from 'nft/components/icons'
import { ArrowUpRight } from 'react-feather'
import styled from 'styled-components/macro'
import { colors } from 'theme/colors'
import { useDarkModeManager } from 'theme/components/ThemeToggle'
//import PdaySac from '../../assets/images/pday-sac-banner.png'

const L2Icon = styled.img`
  width: 24px;
  height: 24px;
  margin-right: 16px;
`

const BodyText = styled.div`
  color: ${({ color }) => color};
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin: 8px;
  font-size: 15px;
`
const RootWrapper = styled.div`
  margin-top: 10px;
  padding: 0 8px;
  user-select: none;
`

const PiteasMiniIconX = styled(PiteasMiniIcon)`
  padding-left: 10px;
  width: 32px;
`

const SHOULD_SHOW_ALERT = {
  [SupportedChainId.OPTIMISM]: true,
  [SupportedChainId.OPTIMISM_GOERLI]: true,
  [SupportedChainId.ARBITRUM_ONE]: true,
  [SupportedChainId.PULSECHAIN]: true,
  [SupportedChainId.ARBITRUM_GOERLI]: true,
  [SupportedChainId.POLYGON]: true,
  [SupportedChainId.POLYGON_MUMBAI]: true,
  [SupportedChainId.CELO]: true,
  [SupportedChainId.CELO_ALFAJORES]: true,
  [SupportedChainId.BNB]: true,
}

type NetworkAlertChains = keyof typeof SHOULD_SHOW_ALERT

const ContentWrapper = styled.div<{ darkMode: boolean }>`
  background: transparent;
  flex-direction: row;
  overflow: hidden;
  position: relative;
  font-size: 11px;
  display: flex;
  width: 100%;
  user-select: none;
`

const StyledArrowUpRight = styled(ArrowUpRight)`
  margin-left: 12px;
  width: 24px;
  height: 24px;
`

const TEXT_COLORS: { [chainId in NetworkAlertChains]: string } = {
  [SupportedChainId.POLYGON]: 'rgba(130, 71, 229)',
  [SupportedChainId.POLYGON_MUMBAI]: 'rgba(130, 71, 229)',
  [SupportedChainId.CELO]: 'rgba(53, 178, 97)',
  [SupportedChainId.CELO_ALFAJORES]: 'rgba(53, 178, 97)',
  [SupportedChainId.OPTIMISM]: '#ff3856',
  [SupportedChainId.OPTIMISM_GOERLI]: '#ff3856',
  [SupportedChainId.ARBITRUM_ONE]: '#0490ed',
  [SupportedChainId.PULSECHAIN]: '#d4125b',
  [SupportedChainId.BNB]: colors.gold400,
  [SupportedChainId.ARBITRUM_GOERLI]: '#0490ed',
}

function shouldShowAlert(chainId: number | undefined): chainId is NetworkAlertChains {
  return Boolean(chainId && SHOULD_SHOW_ALERT[chainId as unknown as NetworkAlertChains])
}

export function NetworkAlert() {
  const { chainId } = useWeb3React()
  const [darkMode] = useDarkModeManager()

  if (!shouldShowAlert(chainId)) {
    return null
  }

  const chainInfo = getChainInfo(chainId)
  if (!chainInfo) return null

  return (
    <RootWrapper>
      <ContentWrapper darkMode={darkMode}>
        <Row>
          <AutoRow>
            <RowBetween align="right" alignContent="right" textAlign="right" fontWeight={500} color={colors.gray500}>
              Piteas v1.8.0
              <PiteasMiniIconX />
            </RowBetween>
          </AutoRow>
        </Row>
      </ContentWrapper>
    </RootWrapper>
  )
}

{
  // return bridge ? (
  // <RootWrapper>
  // <ContentWrapper chainId={chainId} darkMode={darkMode} logoUrl={logoUrl}>
  //       <LinkOutToBridge href={bridge}>
  //         <BodyText color={textColor}>
  //           <L2Icon src={logoUrl} />
  //           <AutoRowX>
  //             <Header>
  //               <Trans>{label} Bridge</Trans>
  //             </Header>
  //             <HideSmallX>
  //               <Trans>Deposit tokens to the {label} now!</Trans>
  //             </HideSmallX>
  //           </AutoRowX>
  //         </BodyText>
  //         <StyledArrowUpRight color={textColor} />
  //       </LinkOutToBridge>
  // </ContentWrapper>
  // </RootWrapper>
  // ) : null
  //
}
