import styled from 'styled-components/macro'

import { MAX_WIDTH_MEDIA_BREAKPOINT } from '../constants'

const GridContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: ${MAX_WIDTH_MEDIA_BREAKPOINT};
  //background-color: ${({ theme }) => theme.backgroundSurface};
  //box-shadow: 0px 0px 1px rgba(0, 0, 0, 0.01), 0px 4px 8px rgba(0, 0, 0, 0.04), 0px 16px 24px rgba(0, 0, 0, 0.04),
    0px 24px 32px rgba(0, 0, 0, 0.01);
  margin-left: auto;
  margin-right: auto;
  border-radius: 12px;
  justify-content: center;
  align-items: center;
  //border: 1px solid ${({ theme }) => theme.backgroundOutline};
`

function BridgeTable() {
  return (
    <GridContainer>
      <iframe
        src="https://bridge.pulsechain.com/#/bridge"
        title="Pulsechain Bridge"
        style={{ width: '100%', height: '780px', border: 'none' }}
      ></iframe>
    </GridContainer>
  )
}

export default BridgeTable
