import { RouterPreference } from 'state/routing/slice'
import { useRouterPreference } from 'state/user/hooks'
import { ThemedText } from 'theme'
import styled from 'styled-components/macro'
import { Map } from 'react-feather'

const RouteIcon = styled(Map)<{ open?: boolean }>`
  vertical-align: middle;
  padding-left: 2px;
  margin-top: -4px;
`

export default function RouterLabel() {
  const [routerPreference] = useRouterPreference()

  switch (routerPreference) {
    case RouterPreference.AUTO:
    case RouterPreference.API:
      return (
        <ThemedText.BodySmall color="textPiteas" fontWeight={700} fontSize="13px">
          Show Route{' '}
          <RouteIcon
            width="15px"
            height="15px"
          />
        </ThemedText.BodySmall>
      )
    case RouterPreference.CLIENT:
      return <ThemedText.BodySmall>Piteas Client</ThemedText.BodySmall>
  }
}

export function RouterLabelX() {
  const [routerPreference] = useRouterPreference()

  switch (routerPreference) {
    case RouterPreference.AUTO:
    case RouterPreference.API:
      return (
        <ThemedText.BodySmall color="textSecondary" fontWeight={600} fontSize="13px">
          Route Details{' '}
        </ThemedText.BodySmall>
      )
    case RouterPreference.CLIENT:
      return <ThemedText.BodySmall>Piteas Client</ThemedText.BodySmall>
  }
}
