import { Trans } from '@lingui/macro'
import { Protocol } from '@uniswap/router-sdk'
import { Currency, Percent } from '@uniswap/sdk-core'
import { FeeAmount } from '@uniswap/v3-sdk'
import Badge from 'components/Badge'
import DoubleCurrencyLogo from 'components/DoubleLogo'
import CurrencyLogo from 'components/Logo/CurrencyLogo'
import Row, { AutoRow } from 'components/Row'
import { useTokenInfoFromActiveList } from 'hooks/useTokenInfoFromActiveList'
import { Box } from 'rebass'
import styled from 'styled-components/macro'
import { ThemedText } from 'theme'
import { Z_INDEX } from 'theme/zIndex'
import { RoutingDiagramEntryX, RoutingDiagramPathX, RoutingDiagramPoolX } from 'utils/getRoutingDiagramEntries'
import Column from 'components/Column'
import { ReactComponent as DotLine } from '../../assets/svg/dot_line.svg'
import { MouseoverTooltip } from '../Tooltip'
import { MEDIA_WIDTHS } from 'theme'

const Wrapper = styled(Box)`
  align-items: center;
  width: 100%;
  overflow-x: overlay;
`

const RouteContainerRow = styled(Row)`
  display: grid;
  grid-template-columns: 40px 1fr 40px;
  @media screen and (max-width: ${MEDIA_WIDTHS.deprecated_upToExtraSmall}px) {
    grid-template-columns: 20px 1fr 20px;
    width: max-content;
  }
`

const HeadCol = styled(Column)`
  border-radius: 6px;
  margin-left: 5px;
  text-align: center;
  text-align: -webkit-center;
  z-index: 1;
  border: 1px solid ${({ theme }) => theme.backgroundInteractive};
  background-color: ${({ theme }) => theme.backgroundSurface};
`
const RouteRowX = styled(Row)`
  align-items: space-evenly;
  display: flex;
  justify-content: space-evenly;
  //padding: 0rem 0.5rem;
  padding: 0.5rem 0.5rem;
  position: relative;
  @media screen and (max-width: ${MEDIA_WIDTHS.deprecated_upToExtraSmall}px) {
   zoom: 0.85;
  }
`

const RouteRow = styled(Row)`
  align-items: center;
  display: flex;
  justify-content: center;
  padding: 0.1rem 0.5rem;
  position: relative;
`

const PoolBadge = styled(Badge)`
  display: flex;
  padding: 4px 4px;
`

const DottedLine = styled.div`
  display: flex;
  align-items: center;
  position: absolute;
  width: calc(100%);
  z-index: 1;
  opacity: 0.1;
`

const DotColor = styled(DotLine)`
  path {
    stroke: ${({ theme }) => theme.deprecated_bg4};
  }
`

const OpaqueBadge = styled(Badge)`
  background-color: transparent;
  border-radius: 6px;
  display: grid;
  font-size: 12px;
  grid-gap: 0px;
  grid-auto-flow: column;
  justify-content: start;
  padding: 0px;
  z-index: ${Z_INDEX.sticky};
`
const OpaqueBadgeX = styled(Badge)`
  background-color: ${({ theme }) => theme.backgroundInteractive};
  border-radius: 4px;
  display: grid;
  font-size: 12px;
  grid-gap: 4px;
  grid-auto-flow: column;
  justify-content: start;
  padding: 4px 6px 4px 4px;
  z-index: ${Z_INDEX.sticky};
`

const ProtocolBadge = styled(Badge)`
  background-color: transparent;
  border-radius: 0px;
  color: ${({ theme }) => theme.textSecondary};
  font-size: 11px;
  width: 80px;
  padding: 4px 4px;
  z-index: ${Z_INDEX.sticky + 1};
`

const ProtocolBadgeX = styled(Badge)`
  background-color: ${({ theme }) => theme.backgroundInteractive};
  border-radius: 6px 6px 0px 0px;
  color: ${({ theme }) => theme.textPrimary};
  font-size: 11px;
  padding: 4px 4px;
  gap: 3px;
  font-weight: 600;
  width: 120px;
  z-index: ${Z_INDEX.sticky + 1};
`

const MixedProtocolBadge = styled(ProtocolBadge)`
  width: 60px;
`

const BadgeText = styled(ThemedText.DeprecatedSmall)`
  word-break: normal;
`

export default function RoutingDiagram({
  currencyIn,
  currencyOut,
  routes,
}: {
  currencyIn: Currency
  currencyOut: Currency
  routes: RoutingDiagramEntryX[]
}) {
  const tokenIn = useTokenInfoFromActiveList(currencyIn)
  const tokenOut = useTokenInfoFromActiveList(currencyOut)

  return (
    <Wrapper>
      {routes.map((entry, index) => (
        <RouteContainerRow key={index}>
          <CurrencyLogo currency={tokenIn} size="22px" />
          <Route entry={entry} />
          <CurrencyLogo currency={tokenOut} size="22px" />
        </RouteContainerRow>
      ))}
    </Wrapper>
  )
}

function Route({ entry: { percent, routes } }: { entry: RoutingDiagramEntryX }) {
  return (
    <RouteRowX>
      <DottedLine>
        <DotColor />
      </DottedLine>
      <OpaqueBadgeX>
        {/*protocol === Protocol.MIXED ? (
          <MixedProtocolBadge>
            <BadgeText fontSize={11}>V3 + V2</BadgeText>
          </MixedProtocolBadge>
        ) : (
          <ProtocolBadge>
            <BadgeText fontSize={11}>{protocol.toUpperCase()}</BadgeText>
          </ProtocolBadge>
        )*/}
        <BadgeText fontSize={12} style={{ minWidth: 'auto' }}>
          {percent.toFixed(2)}%
        </BadgeText>
      </OpaqueBadgeX>
      <Row justify="space-around" align="center">
        {routes.map((subroutes: any, index) => (
          <Subroute entry={subroutes} key={index} />
        ))}
        {/*<AutoRow gap="1px" width="100%" style={{ justifyContent: 'space-evenly', zIndex: 2 }}>
        {path.map(([currency0, currency1, feeAmount], index) => (
          <Pool key={index} currency0={currency0} currency1={currency1} feeAmount={feeAmount} />
        ))}
      </AutoRow>*/}
      </Row>
    </RouteRowX>
  )
}

function Subroute({ entry: { percent, paths, tokenA, tokenB } }: { entry: RoutingDiagramPathX }) {
  const tokenInfo0 = useTokenInfoFromActiveList(tokenA)
  const tokenInfo1 = useTokenInfoFromActiveList(tokenB)

  return (
    <HeadCol>
      <AutoRow
        style={{
          justifyContent: 'space-around!important',
        }}
      >
        <OpaqueBadge>
          <ProtocolBadgeX>
            {/*<CurrencyLogo currency={tokenIn} size={'24px'} /> {tokenIn?.symbol}*/}
            {/*tokenInfo0 && tokenInfo1 ? (
              <DoubleCurrencyLogo currency0={tokenInfo1} currency1={tokenInfo0} size={17} />
            ) : null*/}
            {tokenInfo0?.symbol} ➜ {tokenInfo1?.symbol}
          </ProtocolBadgeX>
        </OpaqueBadge>
      </AutoRow>
      <AutoRow
        gap="0px"
        style={{
          justifyContent: 'space-evenly',
          padding: '4px 0px',
          borderRadius: '6px',
          display: 'inline-block',
          alignItems: 'baseline',
          zIndex: 2,
        }}
      >
        {paths.map((path: any, index) => (
          <Path key={index} percent={path.percent} protocol={path.protocol} />
        ))}
      </AutoRow>
    </HeadCol>
  )
}

function Path({ percent, protocol }: { percent: Percent; protocol: string }) {
  // TODO - link pool icon to info.uniswap.org via query params
  // tokenInfo0?.symbol + '/' + tokenInfo1?.symbol + ' ' +
  return (
    <AutoRow>
      <OpaqueBadge>
        <ProtocolBadge>
          <BadgeText fontSize={11} fontWeight={600}>
            {protocol}{' '}
          </BadgeText>
        </ProtocolBadge>
        <BadgeText fontSize={11} style={{ minWidth: 'auto' }}>
          {percent.toSignificant(3)}%
        </BadgeText>
      </OpaqueBadge>
      {/*<AutoRow gap="1px" width="100%" style={{ justifyContent: 'space-evenly', zIndex: 2 }}>
        {path.map(([currency0, currency1, feeAmount], index) => (
          <Pool key={index} currency0={currency0} currency1={currency1} feeAmount={feeAmount} />
        ))}
      </AutoRow>*/}
    </AutoRow>
  )
}
