import { Protocol } from '@uniswap/router-sdk'
import { Currency, Percent, TradeType } from '@uniswap/sdk-core'
import { Pair } from '@uniswap/v2-sdk'
import { FeeAmount } from '@uniswap/v3-sdk'
import { InterfaceTrade } from 'state/routing/types'
import JSBI from 'jsbi'

export interface RoutingDiagramEntry {
  percent: Percent
  path: [Currency, Currency, FeeAmount][]
  protocol: Protocol
}

const V2_DEFAULT_FEE_TIER = 3000

export interface RoutingDiagramPoolX {
  percent: Percent
  protocol: string
}

export interface RoutingDiagramPathX {
  percent: Percent
  tokenA:Currency,
  tokenB:Currency,
  paths: [RoutingDiagramPoolX]
}

export interface RoutingDiagramEntryX {
  percent: Percent
  routes: [RoutingDiagramPathX]
}

/**
 * Loops through all routes on a trade and returns an array of diagram entries.
 */
export default function getRoutingDiagramEntries(trade: InterfaceTrade): RoutingDiagramEntryX[] {
  return trade.piteasRoutes[0].routes.swaps.map((swap:any) => {
    return {
      percent: new Percent (JSBI.BigInt(swap.percent), 100000),
      routes: swap.subswaps.map((subswap: any) => {
        return {
          tokenA: subswap.tokenA,
          tokenB: subswap.tokenB,
          percent: new Percent (JSBI.BigInt(subswap.percent), 100000),
          paths: subswap.paths.map((path:any) => {
            return {
              percent: new Percent (JSBI.BigInt(path.percent), 100000),
              paths: [path.tokenA, path.tokenB],
              protocol: path.exchange
            }
          })
        }
      }),
    }
  })
  /*
  return trade.swaps.map(({ route: { path: tokenPath, pools, protocol }, inputAmount, outputAmount }) => {
    const portion =
      trade.tradeType === TradeType.EXACT_INPUT
        ? inputAmount.divide(trade.inputAmount)
        : outputAmount.divide(trade.outputAmount)
    const percent = new Percent(portion.numerator, portion.denominator)
    const path: RoutingDiagramEntry['path'] = []
    for (let i = 0; i < pools.length; i++) {
      const nextPool = pools[i]
      const tokenIn = tokenPath[i]
      const tokenOut = tokenPath[i + 1]
      const entry: RoutingDiagramEntry['path'][0] = [
        tokenIn,
        tokenOut,
        nextPool instanceof Pair ? V2_DEFAULT_FEE_TIER : nextPool.fee,
      ]
      path.push(entry)
    }
    return {
      percent,
      path,
      protocol,
    }
  })
  */
}

function getRoutingDiagramEntriesx(trade: InterfaceTrade): RoutingDiagramEntry[] {
  console.log(trade.piteasRoutes[0].routes.swaps)
  return trade.swaps.map(({ route: { path: tokenPath, pools, protocol }, inputAmount, outputAmount }) => {
    const portion =
      trade.tradeType === TradeType.EXACT_INPUT
        ? inputAmount.divide(trade.inputAmount)
        : outputAmount.divide(trade.outputAmount)
    const percent = new Percent(portion.numerator, portion.denominator)
    const path: RoutingDiagramEntry['path'] = []
    for (let i = 0; i < pools.length; i++) {
      const nextPool = pools[i]
      const tokenIn = tokenPath[i]
      const tokenOut = tokenPath[i + 1]
      const entry: RoutingDiagramEntry['path'][0] = [
        tokenIn,
        tokenOut,
        nextPool instanceof Pair ? V2_DEFAULT_FEE_TIER : nextPool.fee,
      ]
      path.push(entry)
    }
    return {
      percent,
      path,
      protocol,
    }
  })
}
